import uploadClient from '../../../clients/upload';

const prepareFiles = async (files) => {
  const newFiles = files.filter(({ id }) => !id);

  if (newFiles.length === 0) {
    return files;
  }

  const uploadedFiles = await uploadClient.upload(newFiles);
  const existingFiles = files.filter(({ id }) => id);
  return [...existingFiles, ...uploadedFiles];
};

export default prepareFiles;
