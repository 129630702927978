import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import Typography from '@material-ui/core/Typography';

import objectsClient from '../../../../clients/objects';

import { fromForm, toForm } from '../../utils/formData';
import validationSchema from '../../utils/validationSchema';
import prepareFiles from '../../utils/prepareFiles';

import Form from '../../components/Form';

const NewObject = () => {
  const history = useHistory();
  const { path } = useRouteMatch();

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (values) => {
    try {
      const Pictures = await prepareFiles(values.Pictures);
      const AudioRecordings = await prepareFiles(values.AudioRecordings);
      const data = fromForm({ ...values, Pictures, AudioRecordings });
      const { id } = await objectsClient.create(data);

      enqueueSnackbar('Objektas sukurtas', { variant: 'success' });

      history.push(path.replace('/new', `/${id}`));
    } catch (error) {
      enqueueSnackbar('Nepavyko sukurti objekto. Bandykite dar kartą.', {
        variant: 'error',
      });
    }
  };

  return (
    <div>
      <Typography variant="h5" gutterBottom>
        Naujas objektas
      </Typography>
      <Formik
        initialValues={toForm({})}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => <Form {...props} />}
      </Formik>
    </div>
  );
};

export default NewObject;
