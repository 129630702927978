import React from 'react';
import PropTypes from 'prop-types';
import ButtonSlim from 'web-app-components/components/ButtonSlim';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

const ConfirmRemoveDialog = ({ open, onConfirm, onClose }) => {
  const handleConfirmClick = () => {
    onClose();
    onConfirm();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <DialogContentText>
          Ar tikrai norite pašalinti šį objektą? Objekto atkurti bus neįmanoma.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonSlim onClick={handleConfirmClick}>Taip</ButtonSlim>
        <ButtonSlim onClick={onClose}>Atšaukti</ButtonSlim>
      </DialogActions>
    </Dialog>
  );
};

ConfirmRemoveDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ConfirmRemoveDialog;
