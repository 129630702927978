import { BrowserRouter as Router } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useAxiosReqAuthIntcpt from 'web-app-components/hooks/useAxiosReqAuthIntcpt';
import AppBar from 'web-app-components/components/AppBar';
import AppContent from 'web-app-components/components/AppContent';
import AppDrawer, {
  useAppDrawer,
} from 'web-app-components/components/AppDrawer';
import { useAuthContext } from 'web-app-components/components/AuthProvider';
import ButtonSlim from 'web-app-components/components/ButtonSlim';

import axiosInstance from './utils/axiosInstance';
import endpoints from './utils/endpoints';

import authClient from './clients/auth';

import SignInForm from './components/SignInForm';

import Navigation from './Navigation';
import Switch from './Switch';

const axiosReqAuthIntcptConfig = {
  shouldExcludeUrl: (url) => url === endpoints.auth,
};

const App = () => {
  const {
    tokenValid,
    signIn,
    signOut,
    state: { token },
  } = useAuthContext();

  const httpClientCofigured = useAxiosReqAuthIntcpt(
    axiosInstance,
    token,
    axiosReqAuthIntcptConfig
  );

  const { enqueueSnackbar } = useSnackbar();

  const appDrawer = useAppDrawer();

  const handleMenuClick = () => appDrawer.setMobileOpen(!appDrawer.mobileOpen);

  const handleSignInFormSubmit = async ({ username, password }) => {
    try {
      signIn(await authClient.auth(username, password));
      enqueueSnackbar('Sveiki!', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(
        'Nepavyko prisijungti. Įsitikinkite, kad prisijungimo duomenys teisingi ir bandykite dar kartą.',
        { variant: 'error' }
      );
    }
  };

  const handleSignOutClick = () => signOut();

  const handleNavigationItemClick = () => appDrawer.onClose();

  return tokenValid && httpClientCofigured ? (
    <Router>
      <AppBar
        text="Objektų administravimas"
        actions={
          <ButtonSlim color="inherit" onClick={handleSignOutClick}>
            Atsijungti
          </ButtonSlim>
        }
        onMenuClick={handleMenuClick}
        enableMenu
      ></AppBar>
      <AppDrawer {...appDrawer}>
        <Navigation onItemClick={handleNavigationItemClick} />
      </AppDrawer>
      <AppContent>
        <Switch />
      </AppContent>
    </Router>
  ) : (
    <SignInForm onSubmit={handleSignInFormSubmit} />
  );
};

export default App;
