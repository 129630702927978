const parseCoordinates = (coordinatesString) => {
  const match =
    /^([+-]?[0-9]+(?:\.|,)[0-9]+), ([+-]?[0-9]+(?:\.|,)[0-9]+)$/g.exec(
      coordinatesString
    );
  if (!match) {
    return null;
  }
  const coordinates = [match[2], match[1]].map((coordinateString) =>
    parseFloat(coordinateString.replace(',', '.'))
  );
  return coordinates;
};

export default parseCoordinates;
