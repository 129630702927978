import React from 'react';
import ReactDOM from 'react-dom';
import { SnackbarProvider } from 'notistack';
import 'web-app-components';
import AppBase from 'web-app-components/components/AppBase';
import AuthProvider from 'web-app-components/components/AuthProvider';

import 'mapbox-gl/dist/mapbox-gl.css';

import reportWebVitals from './reportWebVitals';

import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <AppBase>
      <AuthProvider>
        <SnackbarProvider
          maxSnack={1}
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        >
          <App />
        </SnackbarProvider>
      </AuthProvider>
    </AppBase>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
