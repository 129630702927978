import axiosInstance from '../../utils/axiosInstance';
import endpoints from '../../utils/endpoints';

const find = async (query) => {
  const { data } = await axiosInstance.get(endpoints.objects(), {
    params: { _limit: -1, ...query },
  });
  return data;
};

const findOne = async (id) => {
  const { data } = await axiosInstance.get(endpoints.objects(id));
  return data;
};

const create = async (data) => {
  const { data: object } = await axiosInstance.post(endpoints.objects(), data);
  return object;
};

const update = async (id, data) => {
  const { data: object } = await axiosInstance.put(endpoints.objects(id), data);
  return object;
};

const remove = async (id) => {
  const { data: object } = await axiosInstance.delete(endpoints.objects(id));
  return object;
};

const client = { find, findOne, create, update, remove };

export default client;
