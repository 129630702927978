import axiosInstance from '../../utils/axiosInstance';
import endpoints from '../../utils/endpoints';

const find = async (query) => {
  const { data } = await axiosInstance.get(endpoints.categories(), {
    params: { _limit: -1, ...query },
  });
  return data;
};

const client = { find };

export default client;
