const makeUrlResolver = (endpoint) => (id) =>
  `/${endpoint}${id ? `/${id}` : ''}`;

const endpoints = {
  auth: '/auth/local',
  upload: '/upload',

  objects: makeUrlResolver('objects'),
  categories: makeUrlResolver('categories'),
};

export default endpoints;
