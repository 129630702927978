import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import useDialog from 'web-app-components/hooks/useDialog';
import ButtonSlim from 'web-app-components/components/ButtonSlim';
import { Formik } from 'formik';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import objectsClient from '../../../../clients/objects';

import { toForm, fromForm } from '../../utils/formData';
import validationSchema from '../../utils/validationSchema';
import prepareFiles from '../../utils/prepareFiles';

import Form from '../../components/Form';

import ConfirmRemoveDialog from './components/ConfirmRemoveDialog';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  title: {
    flex: 1,
  },
}));

const ExistingObject = ({ id }) => {
  const classes = useStyles();

  const history = useHistory();
  const { path } = useRouteMatch();

  const { enqueueSnackbar } = useSnackbar();

  const confirmRemoveDialog = useDialog();

  const [data, setData] = useState();

  useEffect(() => {
    (async () => {
      try {
        setData(await objectsClient.findOne(id));
      } catch (error) {
        enqueueSnackbar(
          'Nepavyko gauti objekto. Bandykite atidaryti iš naujo.',
          { variant: 'error' }
        );
      }
    })();
  }, [id, enqueueSnackbar]);

  const handleRemoveClick = async () => confirmRemoveDialog.setOpen(true);

  const handleRemoveConfirm = async () => {
    try {
      await objectsClient.remove(id);
      enqueueSnackbar('Objektas pašalintas', { variant: 'success' });
      history.push(`${path.replace('/:id', '')}`);
    } catch (error) {
      enqueueSnackbar('Nepavyko pašalinti objekto. Bandykite dar kartą.', {
        variant: 'error',
      });
    }
  };

  const handleSubmit = async (values) => {
    try {
      const Pictures = await prepareFiles(values.Pictures);
      const AudioRecordings = await prepareFiles(values.AudioRecordings);
      const data = fromForm({ ...values, Pictures, AudioRecordings });
      await objectsClient.update(id, data);
      enqueueSnackbar('Objektas atnaujintas', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Nepavyko atnaujinti objekto. Bandykite dar kartą.', {
        variant: 'error',
      });
    }
  };

  return data ? (
    <div>
      <div className={classes.header}>
        <Typography className={classes.title} variant="h5">
          Objektas "{data.Title}"
        </Typography>
        <ButtonSlim onClick={handleRemoveClick}>Pašalinti objektą</ButtonSlim>
      </div>
      <Formik
        initialValues={toForm(data)}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => <Form {...props} />}
      </Formik>
      <ConfirmRemoveDialog
        {...confirmRemoveDialog}
        onConfirm={handleRemoveConfirm}
      />
    </div>
  ) : (
    <CircularProgress />
  );
};

ExistingObject.propTypes = {
  id: PropTypes.number.isRequired,
};

export default ExistingObject;
