import React, { useRef, useEffect, useState } from 'react';
import { useField } from 'formik';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
// NOTE for some reason Mapbox docs said to import with exclamation mark. it has
// something to do with excluding Mapbox module from transpilation to ES5...
// see https://docs.mapbox.com/help/tutorials/use-mapbox-gl-js-with-react/#add-mapbox-gl-js
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

const MAP_STYLE =
  'mapbox://styles/map-zemaitijoskeliais/ckj16z1zi9bxp19pfuwjzd9l2';

// NOTE some place in Samogitia
const DEFAULT_LNG = 21.8568;
const DEFAULT_LAT = 55.9011;

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    with: '100%',
    height: '400px',
  },
}));

const LocationField = (props) => {
  const classes = useStyles();

  const [{ value }, { error }, { setValue }] = useField(props);

  const [initialValue] = useState(value);

  const mapContainerRef = useRef();
  const mapRef = useRef();
  const markerRef = useRef();

  useEffect(() => {
    if (mapRef.current) return; // initialize map only once

    const map = (mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: MAP_STYLE,
      center: initialValue
        ? [initialValue.lng, initialValue.lat]
        : [DEFAULT_LNG, DEFAULT_LAT],
      zoom: 14,
    }));

    const geolocateControl = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
      trackUserLocation: true,
    });
    map.addControl(geolocateControl);

    map.on('load', () => {
      if (!initialValue) {
        geolocateControl.trigger();
      }
    });

    map.on('click', ({ lngLat }) => setValue(lngLat));
  }, [initialValue, setValue]);

  useEffect(() => {
    if (!value) return;

    if (!markerRef.current) {
      markerRef.current = new mapboxgl.Marker()
        .setLngLat(value)
        .addTo(mapRef.current);
    } else {
      markerRef.current.setLngLat(value);
    }
  }, [value]);

  return (
    <div>
      <div ref={mapContainerRef} className={classes.mapContainer} />
      {error && <Typography color="error">{error}</Typography>}
    </div>
  );
};

export default LocationField;
