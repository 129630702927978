import { Route, Switch as RRDSwitch } from 'react-router-dom';

import Objects from './features/Objects';

const Switch = () => {
  return (
    <RRDSwitch>
      <Route path="/objects">
        <Objects />
      </Route>
      <Route path="">Sveiki</Route>
    </RRDSwitch>
  );
};

export default Switch;
