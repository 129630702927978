import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import SelectField from 'web-app-components/components/SelectField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';

import categoriesClient from '../../../../../../clients/categories';

import makeCategoriesMap from './utils/makeCategoriesMap';
import sortSelected from './utils/sortSelected';

const useStyles = makeStyles((theme) => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const CategoriesSelectField = (props) => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [categories, setCategories] = useState();

  useEffect(() => {
    (async () => {
      try {
        setCategories(await categoriesClient.find({ _sort: 'Order:asc' }));
      } catch (error) {
        enqueueSnackbar(
          'Nepavyko gauti objektų kategorijų. Bandykite atidaryti iš naujo.',
          { variant: 'error' }
        );
      }
    })();
  }, [enqueueSnackbar]);

  const renderValue = (selected) => {
    const categoriesMap = makeCategoriesMap(categories);
    const selectedSorted = sortSelected(selected, categoriesMap);
    return (
      <div className={classes.chips}>
        {selectedSorted.map((id) => (
          <Chip
            className={classes.chip}
            key={id}
            label={categoriesMap[id].Title}
          />
        ))}
      </div>
    );
  };

  return categories ? (
    <SelectField multiple renderValue={renderValue} {...props}>
      {categories.map(({ id, Title }) => (
        <MenuItem key={id} value={id}>
          {Title}
        </MenuItem>
      ))}
    </SelectField>
  ) : (
    <CircularProgress />
  );
};

export default CategoriesSelectField;
