import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import ButtonSlim from 'web-app-components/components/ButtonSlim';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import Paper from '@material-ui/core/Paper';
import { DataGrid } from '@material-ui/data-grid';

import objectsClient from '../../../../clients/objects';

import FilterSelectInput, { filters } from './components/FilterSelectInput';

const columns = [
  {
    field: 'Title',
    headerName: 'Pavadinimas',
    flex: 1,
    sortable: false,
  },
];

const useStyles = makeStyles((theme) => ({
  controls: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(1),

    '& > :not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
}));

const ObjectsList = () => {
  const classes = useStyles();

  const history = useHistory();
  const { path } = useRouteMatch();

  const { enqueueSnackbar } = useSnackbar();

  const [filter, setFilter] = useState(filters.ALL);

  const [objects, setObjects] = useState();

  useEffect(() => {
    (async () => {
      try {
        const query = { _sort: 'created_at:desc' };
        if (filter === filters.NO_CATEGORIES) {
          query.categories_null = true;
        }
        setObjects(await objectsClient.find(query));
      } catch (error) {
        enqueueSnackbar(
          'Nepavyko gauti objektų sąrašo. Bandykite atidaryti iš naujo.',
          { variant: 'error' }
        );
      }
    })();
  }, [filter, enqueueSnackbar]);

  const handleFilterChange = ({ target: { value } }) => setFilter(value);

  const handleNewObjectClick = () => history.push(`${path}/new`);

  const handleRowClick = ({ id }) => history.push(`${path}/${id}`);

  return (
    <div>
      <div className={classes.controls}>
        <FilterSelectInput
          label="Rodinys"
          value={filter}
          onChange={handleFilterChange}
        />
        <ButtonSlim startIcon={<AddIcon />} onClick={handleNewObjectClick}>
          Naujas objektas
        </ButtonSlim>
      </div>
      <Paper>
        <DataGrid
          columns={columns}
          rows={objects || []}
          loading={!objects}
          onRowClick={handleRowClick}
          autoHeight
          disableColumnMenu
          disableSelectionOnClick
        />
      </Paper>
    </div>
  );
};

export default ObjectsList;
