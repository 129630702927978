import PropTypes from 'prop-types';
import ListItemLink from 'web-app-components/components/ListItemLink';
import List from '@material-ui/core/List';

const Navigation = ({ onItemClick }) => {
  return (
    <List>
      <ListItemLink to="/objects" text="Objektai" onClick={onItemClick} />
      <ListItemLink
        to="/objects/new"
        text="Kurti naują objektą"
        onClick={onItemClick}
      />
    </List>
  );
};

Navigation.propTypes = {
  onItemClick: PropTypes.func.isRequired,
};

export default Navigation;
