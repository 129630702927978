import { Route, Switch as RRDSwitch, useRouteMatch } from 'react-router-dom';

import NewObject from './features/NewObject';
import ExistingObject from './features/ExistingObject';
import ObjectsList from './features/ObjectsList';

const Switch = () => {
  const { path } = useRouteMatch();

  return (
    <RRDSwitch>
      <Route path={`${path}/new`}>
        <NewObject />
      </Route>
      <Route
        path={`${path}/:id`}
        render={({
          match: {
            params: { id },
          },
        }) => <ExistingObject id={parseInt(id)} />}
      />
      <Route path={`${path}`}>
        <ObjectsList />
      </Route>
    </RRDSwitch>
  );
};

export default Switch;
