import { valueToForm, arrayToForm } from 'web-app-components/utils/formInputs';

import parseCoordinates from '../../../utils/parseCoordinates';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const coordinatesToForm = (coordinates) => {
  const lngLatArr = parseCoordinates(coordinates);

  if (!lngLatArr) {
    return null;
  }

  const [lng, lat] = lngLatArr;
  return { lng, lat };
};

const formToCoordinates = (coordinates) =>
  `${coordinates.lat}, ${coordinates.lng}`;

export const toForm = ({
  Title,
  Description,
  Coordinates,
  categories,
  VideoURL,
  Pictures,
  AudioRecordings,
}) => ({
  Title: valueToForm(Title),
  Description: valueToForm(Description),
  Coordinates: coordinatesToForm(Coordinates),
  categories: arrayToForm(categories).map(({ id }) => id),
  VideoURL: valueToForm(VideoURL),
  Pictures: arrayToForm(Pictures).map(({ id, name, ext, formats }) => ({
    id,
    name: `${name}${ext}`,
    url: `${SERVER_URL}${formats.thumbnail.url}`,
  })),
  AudioRecordings: arrayToForm(AudioRecordings).map(
    ({ id, name, ext, url }) => ({
      id,
      name: `${name}${ext}`,
      url: `${SERVER_URL}${url}`,
    })
  ),
});

export const fromForm = ({
  Title,
  Description,
  Coordinates,
  categories,
  VideoURL,
  Pictures,
  AudioRecordings,
}) => ({
  Title,
  Description,
  Coordinates: formToCoordinates(Coordinates),
  categories,
  VideoURL,
  Pictures,
  AudioRecordings,
});
