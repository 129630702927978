import React from 'react';
import PropTypes from 'prop-types';
import { Form as FormikForm } from 'formik';
import TextField from 'web-app-components/components/TextField';
import ButtonPrimary from 'web-app-components/components/ButtonPrimary';
import makeStyles from '@material-ui/core/styles/makeStyles';

import LocationField from './components/LocationField';
import CategoriesSelectField from './components/CategoriesSelectField';
import FilesField from './components/FilesField';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > :not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
}));

const Form = ({ isSubmitting }) => {
  const classes = useStyles();

  return (
    <FormikForm>
      <div className={classes.root}>
        <LocationField name="Coordinates" />
        <TextField name="Title" label="Pavadinimas" fullWidth />
        <TextField
          name="Description"
          label="Aprašymas"
          multiline
          rows={4}
          fullWidth
        />
        <FilesField
          name="Pictures"
          accept="image/*"
          label="Nuotraukos"
          disabled={isSubmitting}
        />
        <FilesField
          name="AudioRecordings"
          accept="audio/*"
          label="Garso įrašai"
          disabled={isSubmitting}
        />
        <CategoriesSelectField
          name="categories"
          label="Kategorijos"
          fullWidth
        />
        <TextField name="VideoURL" label="Video nuoroda" fullWidth />
        <ButtonPrimary type="submit" disabled={isSubmitting}>
          Išsaugoti
        </ButtonPrimary>
      </div>
    </FormikForm>
  );
};

Form.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
};

export default Form;
